import * as React from "react";
import { Fragment } from "react";
import ReactTooltip  from "react-tooltip";

interface TooltipProps {
  id: string;
  text: string;
  exclamation?: boolean;
  inStyle?: object;
}

const Tooltip: React.FC<TooltipProps> = ({
  id,
  text,
  exclamation,
  inStyle,
  children
}): JSX.Element => {
  const common = { zIndex: 10, top: "-4px", right: "-20px" }
  const params = inStyle ? inStyle : common

  return (
    <Fragment>
      <div className="position-relative d-flex align-items-center">
        {children}
        <div className="position-absolute" data-tip={text} data-for={id} style={params}>
          <svg className="cursor-pointer"  width={18} height={18} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            {exclamation ? (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z">
                </path>
            ) : (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                  d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z">
                </path>
            )}
          </svg>
        </div>
      </div>
      <ReactTooltip id={id} place="right" />
    </Fragment>

  );
};

export default Tooltip;
