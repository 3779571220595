import * as React from "react";
import cx from "classnames";

interface ButtonPrevProps {
  disabled?: boolean;
  onClick: () => void;
}

const ButtonPrev: React.FC<ButtonPrevProps> = ({ disabled, onClick }) => {
  return (
    <a
      className={
        cx(disabled && "opacity-5" , "d-flex align-items-center text-grey-500 hover:text-grey-700 text-decoration-none")
      }
      href="#"
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      <svg
        className="mr-2"
        width={20}
        height={20}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fillRule="evenodd"
          d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
          clipRule="evenodd"
        />
      </svg>
      Precedente
    </a>
  )
}

export default ButtonPrev;
