import * as React from "react";
import {useEffect, useState} from "react";
import axios from "axios";
import * as toastr from "toastr";

export default function AdminChangePassword(props) {
    const [formState, setFormState] = useState({
        email: '',
        password: ''
    })

    const onInputChanged = (e) => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        axios
            .post("/admins/password",
                {
                    ...formState, authenticity_token: props.token
                }, {headers: {Accept: 'application/json'}}
            )
            .then(() => {
                window.location.href = '/admins'
            })
            .catch((error) => {
                toastr.error(error.response.data.message);
            });
    };

    return (

        <div className="admin-change-password">
            <div className="container">
                <div className="row text-center">
                    <div className="col-12">
                        <img src="/images/nwitaly_logo.svg" alt="logotype" />
                    </div>
                </div>

                <div className="row admin-form-wrapper">
                    <div className="col-12">

                        <div className="row text-center">
                            <div className="col-12">
                                <h3>Cambia la tua password</h3>
                            </div>
                        </div>

                        <div className="row text-right">
                            <div className="col-12 col-md-6 admin-form">
                                <form className="form" action="post" method="/admins" onSubmit={onSubmit}>
                                    <div className="container">
                                        <div className="row">

                                            <div className="col-12 code-form">
                                                <label>
                                                    <p>Inserisci la tua vecchia password:</p>
                                                    <input type="password"
                                                           name="password_old"
                                                           placeholder="Vecchia password"
                                                           required
                                                           onChange={onInputChanged}
                                                    />
                                                </label>
                                            </div>
                                            <div className="col-12 code-form">
                                                <label>
                                                    <p>Inserisci la tua nuova password:</p>
                                                    <input type="password"
                                                           name="password"
                                                           placeholder="Nuova password"
                                                           required
                                                           onChange={onInputChanged}
                                                    />
                                                </label>
                                            </div>

                                        </div>

                                        <button type="submit" className="blue_button">Modificare</button>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
};
