import * as React from "react";
import {useEffect, useState} from "react";
import axios from "axios";
import * as toastr from "toastr";

export default function AdminLogin(props) {
    const [formState, setFormState] = useState({
        email: '',
        password: ''
    })

    const onInputChanged = (e) => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        axios
            .post("/admins/sign_in", {admin: formState, authenticity_token: props.token})
            .then(() => {
                window.location.href = '/admins'
            })
            .catch((error) => {
                toastr.error(error.response.data.message);
            });
    };

    return (
        <div className="admin-login">
            <div className="container">
                <div className="row text-center">
                    <div className="col-12">
                        <img src="/images/nwitaly_logo.svg" alt="logotype" />
                    </div>
                </div>

                <div className="row admin-form-wrapper justify-content-center">
                    <div className="col-12 col-md-8 col-md-6 with-border">

                        <div className="row text-center">
                            <div className="col-12">
                                <h3>Backoffice</h3>
                            </div>
                        </div>

                        <div className="row text-right">
                            <div className="col-12 admin-form">
                                <form className="form" action="post" method="/admins" onSubmit={onSubmit}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 code-form">
                                                <label>
                                                    <p>Inserisci il tuo indirizzo email:</p>
                                                    <input type="text"
                                                           name="email"
                                                           placeholder="Email"
                                                           required
                                                           onChange={onInputChanged}
                                                    />
                                                </label>
                                            </div>
                                            <div className="col-12 code-form">
                                                <label>
                                                    <p>Inserisci la tua password:</p>
                                                    <input type="password"
                                                           name="password"
                                                           placeholder="Password"
                                                           required
                                                           onChange={onInputChanged}
                                                    />
                                                </label>
                                            </div>
                                        </div>

                                        <button type="submit" className="blue_button">Accedi</button>

                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};