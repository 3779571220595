import * as React from "react";

interface ButtonNextProps {
  onClick: (e: React.MouseEvent) => void;
}

const ButtonRemove: React.FC<ButtonNextProps> = ({ onClick }) => {
  return (
    <svg
      className="cursor-pointer"
      onClick={onClick}
      width={20}
      height={20}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#990f02"
      aria-hidden="true"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
      ></path>
    </svg>
  )
}

export default ButtonRemove;
