import * as React from "react";
import "toastr/build/toastr.min.css";
import {Button} from "react-bootstrap";
import axios from "axios";
import * as toastr from "toastr";

function buttonClick(e) {
    e.preventDefault()
    const element = document.querySelector('.pop-up-menu');
    element.classList.toggle('visible');
}

export default function AdminTopPanel(props) {
    const onLogout = (event) => {
        axios.delete("/admins/sign_out", {
            headers: {Accept: 'application/json'},
            data: {authenticity_token: props.token}
        })
            .then(() => {
                window.location.href = '/'
            })
            .catch((error) => {
                toastr.error(error.response.data.message);
            });
    };

    return (
        <div className="top-menu">
            <div className="container-fluid">
                <div className="row text-center">
                    <div className="col-4 col-md-2">
                        <a className="logo" href="#"><img src="/images/nwitaly_logo.svg" alt="logotype"/></a>
                    </div>

                    <div className="col-7 col-md-8">
                        <ul className="nav justify-content-center">
                            <li className="nav-item">
                                <a className="nav-link" href="/admins">Amministratori</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/admins/contacts">Contatti</a>
                            </li>
                        </ul>
                    </div>

                    <div className="col-1 col-md-2 text-right logout_button">
                        <a href="#"><img src="/images/logout.svg" alt="logout_icon" onClick={buttonClick}/></a>
                    </div>

                    <ul className="pop-up-menu">
                        <li><p>{props.admin.email}</p></li>
                        <li><Button variant="primary" onClick={onLogout}>Logout</Button></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
