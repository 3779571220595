import * as React from "react";

export const TermsAndConditions = () => (
    <div className="container">
        <div className="row form-content">
            <div className="col-12">
                <p className="label">INFORMATIVA PRIVACY AI SENSI DELL’ART. 13 DEL REGOLAMENTO EUROPEO (UE) 2016/679</p>
                <p>Ai sensi e per gli effetti degli artt. 13 del Regolamento Europeo (UE) 2016/679 in materia di protezione dei dati personali (breviter <span>“GDPR”</span>), in ottemperanza al principio di trasparenza, si rendono note le seguenti informazioni in relazione al conferimento e al trattamento dei Suoi dati personali.</p>

                <ol className="list-info">
                    <li>
                        <p className="label">TITOLARE DEL TRATTAMENTO DEI DATI</p>
                        <p className="description">Ai sensi degli artt. 4, comma 7, e 24 del GDPR, il Titolare del Trattamento dei Suoi dati personali è New Wave Italia S.r.l., P.IVA 12500150151, in persona del legale rappresentante dott. Mario Bianchi, con sede amministrativa in Codogno (LO) (Zona Mirandolina), Via P. Togliatti 28, cap 26845, indirizzo e-mail: <a className="link" href="#"> privacy@newwave.it.</a></p>
                    </li>

                    <li>
                        <p className="label">RESPONSABILE DELLA PROTEZIONE DEI DATI</p>
                        <p className="description">Ai sensi dell’art. 37 del GDPR, New Wave Italia S.r.l ha provveduto a nominare il Responsabile della Protezione dei Dati Personali, designando LTA Studio Tax &nbsp; Law Firm, P.IVA 07085920960, con sede in Milano (Mi), Via G. Leopardi n. 7, cap. 20123, in persona dell’avv. Francesca Gusti, contattabile all’indirizzo e-mail: <a className="link" href="#">dpo@ltaa.it.</a></p>
                    </li>

                    <li>
                        <p className="label">BASE GIURIDICA E FINALITA’ DEL TRATTAMENTO</p>
                        <p className="description">La informiamo che i Suoi dati personali saranno trattati: </p>
                        <ol type="a" className="list-symbol">
                            <li>
                                <p className="description">per finalità connesse agli obblighi previsti da leggi, regolamenti, normative comunitarie, nonché da disposizioni impartite dalle competenti autorità/organi di vigilanza e controllo nonché per esercitare i diritti del Titolare del Trattamento (compreso, ad esempio, il diritto di difesa in giudizio);</p>
                            </li>

                            <li>
                                <p className="description">per finalità strettamente connesse e/o necessarie all’adempimento degli obblighi precontrattuali, contrattuali e fiscali derivanti da rapporti con New Wave Italia S.r.l. e/o con società facenti parte del Gruppo New Wave;</p>
                            </li>

                            <li>
                                <p className="description">per finalità di carattere promozionale e/o di marketing, quali trasmissione via e-mail, posta e/o contatti telefonici, newsletter di comunicazioni commerciali e/o materiale pubblicitario (ivi compresi eventuali cataloghi) su prodotti o servizi offerti da New Wave Italia S.r.l. e/o da società facenti parte del Gruppo New Wave.</p>
                            </li>
                        </ol>
                    </li>

                    <li>
                        <p className="label">CONFERIMENTO DEI DATI</p>
                        <p className="description">Il conferimento dei dati per le finalità di cui al precedente punto 3, lett. a] e b], è necessario. L'eventuale rifiuto di comunicare i dati per tali finalità, o comunque il conferimento parziale o inesatto degli stessi, comporterà l'impossibilità da parte di New Wave Italia S.r.l. di adempiere alle proprie obbligazioni. Il conferimento dei dati per le finalità di cui al precedente punto 3, lett. c) è, invece, facoltativo. Può quindi decidere di non conferire alcun dato o di negare successivamente la possibilità di trattare dati già forniti: in tale caso, New Wave Italia S.r.l. non potrà inviarLe comunicazioni e/o materiale commerciale/pubblicitario, compresi i cataloghi illustrativi dei prodotti della società e/o di quelle facenti parte del Gruppo New Wave. Ai sensi dell’art. 7, par. 3, del GDPR, ha la possibilità di revocare il proprio consenso in qualsiasi momento.</p>
                    </li>

                    <li>
                        <p className="label">DESTINATARI ED EVENTUALI CATEGORIE DI DESTINATARI</p>
                        <p className="description">I dati personali saranno resi accessibili sotto la responsabilità del Titolare del Trattamento:</p>

                        <ul className="dot-list">
                            <li>
                                <p className="description">ai Responsabili del Trattamento nominati dal Titolare del Trattamento e dai soggetti da quest’ultimo strettamente autorizzati ex art. 29, nonché alle società del Gruppo New Wave;</p>
                            </li>

                            <li>
                                <p className="description">in caso di ispezioni e/o verifiche (qualora richiestici) a tutti gli organi ispettivi preposti a verifiche e controlli inerenti la regolarità degli adempimenti di legge;</p>
                            </li>

                            <li>
                                <p className="description">alle società/studi professionali che prestano attività di assistenza, consulenza, collaborazione al Titolare del Trattamento, in materia contabile, amministrativa, fiscale, legale, tributaria e finanziaria;</p>
                            </li>

                            <li>
                                <p className="description">a terzi fornitori di servizi cui la comunicazione è necessaria per l’adempimento delle prestazioni oggetto del contratto;</p>
                            </li>

                            <li>
                                <p className="description">a pubbliche amministrazioni per lo svolgimento delle funzioni istituzionali nei limiti stabiliti dalla legge o dai regolamenti.</p>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <p className="label">MODALITÀ DI TRATTAMENTO</p>
                        <p className="description">Il trattamento dei dati personali sarà improntato ai principi di correttezza, liceità, trasparenza e sarà effettuato per mezzo delle operazioni indicate all’art. 4, n. 2, del GDPR e quali, tra l’altro, raccolta, registrazione, organizzazione, conservazione, consultazione, elaborazione, modificazione, selezione, estrazione, raffronto, utilizzo, interconnessione, blocco, comunicazione, cancellazione e distruzione dei dati. I dati personali saranno sottoposti a trattamento sia cartaceo che elettronico e/o automatizzato. I dati vengono custoditi e controllati mediante adozione di idonee misure preventive di sicurezza volte a ridurre al minimo i rischi di perdita e di distruzione, di accesso non autorizzato, di trattamento non consentito e non conforme alle finalità per cui l’assenso alla raccolta è prestato.</p>
                    </li>

                    <li>
                        <p className="label">TRASFERIMENTO DEI DATI</p>
                        <p className="description">I dati raccolti sono conservati su server ubicati in Svezia (precisamente c/o Iptor AB/IP-Only, Datalinjen 5 B, 583 30 Linköping), all’interno quindi dell’Unione Europea. Ai sensi dell’art. 13, comma 1, lettera (f) del GDPR, si informa che i dati personali non saranno oggetto di alcun trasferimento in paesi extra-UE. Tuttavia, qualora si ravvisasse la necessità di trasferire i dati in paesi extra-UE, tale trasferimento sarà effettuato conformemente alle disposizioni di cui al GDPR. Il Titolare del Trattamento si riserva, inoltre, la possibilità di utilizzare servizi in cloud; in tale caso, i fornitori di tali servizi saranno selezionati tra coloro che forniscono adeguate garanzie.</p>
                    </li>

                    <li>
                        <p className="label">DIRITTI DELL’INTERESSATO</p>
                        <p className="description">In ottemperanza alle disposizioni di cui al GDPR, l’interessato ha il diritto, ove applicabile, di chiedere al Titolare del trattamento l’accesso ai dati (art. 15), la rettifica (art 16), la cancellazione degli stessi o l’oblio (art. 17), la limitazione del trattamento dei dati personali che lo riguardano (art. 18), il diritto alla portabilità dei dati (art. 20) o di opporsi al loro trattamento (art. 21), oltre al diritto di non essere sottoposto a una decisione basata unicamente sul trattamento automatizzato, compresa la filiazione, che produca effetti giuridici che lo riguardano o che incida allo stesso modo significativamente sulla sua persona (art. 22). Le richieste potranno essere presentate per iscritto al Titolare del Trattamento ai contatti indicati al punto 1. L’interessato ha, altresì, il diritto di proporre reclamo all’autorità di controllo preposta (art. 77 del Regolamento) qualora ritenga che il trattamento effettuato da parte del Titolare non sia conforme. Per approfondimenti è possibile consultare il sito del Garante Privacy www.garante privacy.it.</p>
                    </li>

                    <li>
                        <p className="label">CONSERVAZIONE DEI DATI</p>
                        <p className="description">Il Titolare del Trattamento conserverà i dati personali per il tempo strettamente necessario per adempiere alle finalità per cui sono stati raccolti e conferiti (secondo quanto previsto ai precedenti punti 3 e 4). I dati Personali potranno essere conservati per un periodo più lungo in ottemperanza ad un obbligo di legge (anche di natura fiscale) o per ordine di un’autorità. Successivamente i dati saranno cancellati o resi inattivi.</p>
                    </li>
                </ol>

                <p className="label">New Wave Italia S.r.l.</p>
            </div>
        </div>
    </div>
);
