import * as React from "react";
import { Fragment, useMemo } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { omit } from "lodash";

import { FormInputs } from "../ClientForm/ClientForm";
import ClientPersonalData from "../ClientForm/ClientPersonalData";
import ClientTaxData from "../ClientForm/ClientTaxData";
import ClientPersonalDataConsent from "../ClientForm/ClientPersonalDataConsent";
import ClientContacts from "../ClientForm/ClientContacts";

import {
  ValidationSchema1,
  ValidationSchema2,
  ValidationSchema3,
  ValidationSchema4
} from "../ClientForm/ValidationSchema";

type Rename<T, K extends keyof T, N extends string> = Pick<T, Exclude<keyof T, K>> & { [P in N]: T[K] }

interface AdminClientContactShowProps {
  onSubmit: (values: Omit<Rename<FormInputs, "contacts", "additional_contacts_attributes">, "company_type_individ">) => void;
  initialValues: FormInputs;
}

export const AdminClientContactForm: React.FC<AdminClientContactShowProps> = ({
  onSubmit,
  initialValues
}) => {
  const validationSchema = useMemo(() => {
    return ValidationSchema4
  }, [ValidationSchema4]);

  const methods = useForm<FormInputs>({
    shouldUnregister: false,
    defaultValues: initialValues
  });

  const { handleSubmit, watch } = methods;

  const onFormSubmit = (values: FormInputs) => {
    const formValues = {} as Rename<FormInputs, "contacts", "additional_contacts_attributes">;
    delete Object.assign(formValues, values, { additional_contacts_attributes: values.contacts })["contacts"];

    const initialContactIds = initialValues.contacts.map(({ id }) => Number(id));
    const updatesIds = formValues.additional_contacts_attributes.map(({ id }) => Number(id));
    const idsToRemove = initialContactIds.filter(id => !updatesIds.includes(id));

    idsToRemove.forEach(id => {
      const removedContact = initialValues.contacts.find(contact => Number(contact.id) === Number(id));
      if (removedContact) {
        formValues.additional_contacts_attributes.push({
          ...removedContact,
          _destroy: true
        });
      }
    });

    onSubmit(
      omit({
        ...formValues,
        ...(formValues.consent_b === "no" && {
          contacts: []
        })
      }, "company_type_individ", "contacts")
    );
  };

  return (
    <FormProvider {...methods}>
      <form className="form" action="post" method="#" onSubmit={handleSubmit(onFormSubmit)}>
        <div className="container">
          <h4 className="text-center mb-2">DATI ANAGRAFICI</h4>
          <ClientPersonalData />

          <h4 className="text-center mt-5 mb-2">DATI FISCALI</h4>
          <ClientTaxData />

          <h4 className="text-center mt-5 mb-2 print-break">CONSENSO AL TRATTAMENTO DEI DATI PERSONALI </h4>
          <ClientPersonalDataConsent />

          <h4 className="text-center mb-2">CONTATTI E COMUNICAZIONI</h4>
          <ClientContacts />
        </div>

        <div className="w-100 bg-dark mt-4 mb-4" style={{ height: "1px" }} />

        <div className="d-flex d-print-none">
          <button className="ml-auto" type="submit">Salva / Conferma</button>
        </div>
      </form>
    </FormProvider>
  );
};
