import * as React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { omit } from "lodash";
import { Fragment, useMemo, useState } from "react";

import {
  ValidationSchema1,
  ValidationSchema2,
  ValidationSchema3,
  ValidationSchema4
} from "./ValidationSchema";

import ClientContacts from "./ClientContacts";
import ClientPersonalData from "./ClientPersonalData";
import ClientTaxData from "./ClientTaxData";
import ClientPersonalDataConsent from "./ClientPersonalDataConsent";
import ButtonPrev from "./ButtonPrev";
import ButtonNext from "./ButtonNext";

export interface FormInputs {
  // 1
  company_name: string;
  company_type: string;
  company_type_individ: string;
  authorization_code: string;
  street: string;
  number: number;
  city: string;
  zip: string;
  province: string;
  sh_street: string;
  sh_number: string;
  sh_city: string;
  sh_zip: string;
  sh_province: string;
  sh_telephone: string;
  sh_fax: string;
  sh_delivery_time: string;
  sh_delivery_notes: string;
  // 2
  vat_number: string;
  fiscal_code: string;
  flat_rate: string;
  split_payment: string;
  banca: string;
  ag_fil: string;
  iban: string;
  payment_type: string;
  recipient_code: number;
  address: string;
  // 3
  consent_a: string;
  consent_b: string;
  // 4
  contacts?: {
    id?: string;
    _destroy?: boolean | string;
    type: string;
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
    receive_emails: boolean;
    primary: boolean;
  }[];
}

export const ContactTypes = {
  AMMINISTRATIVO: 'administrative',
  COMMERCIALE: 'commercial',
  MARKETING: 'marketing',
  ACQUISTI: 'shopping',
  TITOLARE: 'holder',
  MAGAZZINO: 'warehouse'
}

export const ContactTypeOptions = Object.entries(ContactTypes).map(([label, value]) => {
  return { value, label }
});

const validationSchemas = {
  1: ValidationSchema1,
  2: ValidationSchema2,
  3: ValidationSchema3,
  4: ValidationSchema4
}

const developmentValues = {
  authorization_code: "authorization_code",
  company_name: "company_name",
  company_type: "SPA",
  street: "P.O. Box 132 1599 Curabitur Rd",
  number: 235,
  city: "Dakota",
  zip: "45149",
  province: "South Dacota",

  sh_street: "P.O. Box 132 1599 Curabitur Rd",
  sh_number: "236",
  sh_city: "Dakota",
  sh_zip: "45149",
  sh_province: "South Dacota",
  sh_telephone: "14683532641",
  sh_fax: "14683532641",
  sh_delivery_time: "time",
  sh_delivery_notes: "Delivery notes",

  vat_number: "102314",
  fiscal_code: "123103130190010239",
  flat_rate: "SI",
  electronic_invoice_type: "Address",
  electronic_invoice_value: "12301023884192",
  split_payment: "SI",
  banca: "312931931",
  ag_fil: "312931931",
  iban: "UA110102192102931892381039193",
  payment_type: "cart",

  consent_a: "yes",
  consent_b: "no"
}

const defaultValues = {
  contacts: [{
    type: ContactTypes.AMMINISTRATIVO,
    first_name: '',
    last_name: '',
    phone_number: '',
    email: '',
    primary: true,
    receive_emails: true
  }]
}

interface ClientFormProps {
  onSubmit: (values: Omit<FormInputs, "company_type_individ">) => void;
  initialValues: FormInputs;
  submitLabel: string;
}

export const ClientForm: React.FC<ClientFormProps> = ({ onSubmit, submitLabel }) => {
  const [activeStep, setActiveStep] = useState<number>(1);

  const validationSchema = useMemo(() => {
    return validationSchemas[activeStep]
  }, [activeStep]);

  const methods = useForm<FormInputs>({
    shouldUnregister: false,
    defaultValues: { ...defaultValues },
    resolver: yupResolver(validationSchema)
  });
  const { handleSubmit, getValues, reset, watch, trigger } = methods;
  const consentB = watch("consent_b");

  const canGoBack = activeStep > 1;
  const canGoNext = activeStep < 4;

  const reinitializeForm = () => {
    if (activeStep === 3) {
      const currentValues = getValues();
      if (consentB === "yes") {
        const marketing = currentValues.contacts.find(contact => {
          return contact.type === ContactTypes.MARKETING && contact.primary;
        });

        if (!marketing) {
          currentValues.contacts.splice(1, 0, {
            type: ContactTypes.MARKETING,
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            primary: true,
            receive_emails: false
          });
        }
      } else {
        currentValues.contacts = currentValues.contacts.filter(contact => {
          return !(contact.type === ContactTypes.MARKETING && contact.primary);
        });
      }

      reset(currentValues);
    }
  }

  const onPrevClick = () => {
    if (canGoBack) {
      setActiveStep(activeStep - 1);
    }
  }

  const onNextClick = async () => {
    const result = await trigger();
    if (result && canGoNext) {
      reinitializeForm();
      setActiveStep(activeStep + 1);
    }
  };

  const onFormSubmit = (values: FormInputs) => {
    onSubmit(
      omit(values, "company_type_individ")
    );
  };

  return (
    <FormProvider {...methods}>
      <form className="form" action="post" method="#" onSubmit={handleSubmit(onFormSubmit)}>
        <div className="container">
          {({
            1: (
              <Fragment>
                <h3 className="text-center mb-3">DATI ANAGRAFICI</h3>
                <ClientPersonalData />
              </Fragment>
            ),
            2: (
              <Fragment>
                <h3 className="text-center mb-3">DATI FISCALI</h3>
                <ClientTaxData />
              </Fragment>
            ),
            3: (
              <Fragment>
                <h3 className="text-center mb-3">CONSENSO AL TRATTAMENTO DEI DATI PERSONALI </h3>
                <br/>
                <br/>
                <ClientPersonalDataConsent />
              </Fragment>
            ),
            4: (
              <Fragment>
                <h3 className="text-center mb-3">CONTATTI E COMUNICAZIONI</h3>
                <ClientContacts />
              </Fragment>
            )
          })[activeStep]}
        </div>

        <div className="w-100 bg-dark mt-4 mb-4" style={{ height: "1px" }} />

        <div className="d-flex justify-content-between">
          <ButtonPrev disabled={activeStep === 1} onClick={onPrevClick} />
          {canGoNext ? (
            <ButtonNext onClick={onNextClick} />
          ) : (
            <button type="submit">{submitLabel}</button>
          )}
        </div>
      </form>
    </FormProvider>
  );
};
