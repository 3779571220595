import { Button, Modal } from "react-bootstrap";
import * as React from "react";

export const ConfirmationWindow = ({ isOpen, onConfirm, onCancel, onSave }) => (
    <Modal show={isOpen}>
        <Modal.Header closeButton onHide={onCancel}>
            <Modal.Title>Conferma del modulo</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <p>Vuoi confermare il contatto o solo salvare i dati?</p>
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={onCancel}>Cancella</Button>
            <Button variant="primary" onClick={onSave}>Salva</Button>
            <Button variant="primary" onClick={onConfirm}>Conferma</Button>
        </Modal.Footer>
    </Modal>
);