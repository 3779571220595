import * as React from "react";
import FormRadioCheckbox from "./FormRadioCheckbox";
import Tooltip from "./Tooltip";

const ClientPersonalDataConsent: React.FC<{}> = () => {
  return (
    <div className="row form-content">
      <div className="col-12">
        <p className="label">CONSENSO AL TRATTAMENTO DEI DATI PERSONALI</p>
        <p>
          Il sottoscritto, precedentemente identificato, dichiara di aver preso visione dell’informativa pubblicata sulla pagina web <a href="https://www.newwave.it/privacy_policy" target="_blank">privacy policy</a> ai sensi dell’art. 13 del Regolamento UE 2016/679 ed esprime come segue il proprio consenso:
        </p>

        <div className="dark_border">
          <div className="col-10 col-md-5 checkbox-list">
            <FormRadioCheckbox name="consent_a" value="yes" label="Do il consenso" />
            <FormRadioCheckbox name="consent_a" value="no" label="Nego il consenso" />
            <Tooltip id="bottom_hint" exclamation={true} text="CONSENSO AL TRATTAMENTO DEI DATI PERSONALI OBBLIGATORIO" inStyle={{ zIndex: 10, top: "-4px", left: "150px" }}>
            </Tooltip>
          </div>

          <p className="description">al trattamento, da parte di New Wave Italia S.r.l. e/o società facenti
          parte del Gruppo New Wave, dei dati personali per finalità connesse e strumentali per adempiere agli
          obblighi previsti da leggi, regolamenti, normative comunitarie nonché agli obblighi precontrattuali,
                contrattuali e fiscali derivanti da rapporti in essere. </p>
        </div>


        <div className="dark_border">
          <div className="col-10 col-md-5 checkbox-list">
            <FormRadioCheckbox name="consent_b" value="yes" label="Do il consenso" />
            <FormRadioCheckbox name="consent_b" value="no" label="Nego il consenso" />
          </div>

          <p className="description">
            al trattamento, da parte di New Wave Italia S.r.l. e/o società facenti
            parte del Gruppo New Wave, dei dati personali per finalità connesse ad attività promozionale e/o di
            marketing.
                       </p>
          <p className="description">
                <span>
              Le segnaliamo che, qualora dovesse decidere di negare il proprio consenso per tali finalità,
              non potrà più ricevere i cataloghi dei prodotti e/o offerte dedicate di New Wave
              Italia S.r.l. e/o delle società facenti parte del Gruppo New Wave.
                </span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default ClientPersonalDataConsent;
