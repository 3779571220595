import * as React from "react";
import { useFormContext } from "react-hook-form";
import cx from "classnames";

import { FormInputs } from "./ClientForm";

const FormCompanyTypeInput: React.FC<{}> = () => {
  const name = "company_type_indiv_input"
  const { errors, register, watch } = useFormContext<FormInputs>();
  const watchIndivChecked = watch("company_type");

  return (
    <input
      ref={register()}
      className={cx({ invalid: errors[name] })}
      type="text"
      disabled={watchIndivChecked !== 'company_type_individ'}
      name={name}
    />
  );
};

export default FormCompanyTypeInput;
