import * as React from "react";
import cx from "classnames";

interface ButtonNextProps {
  disabled?: boolean;
  onClick: () => void;
}

const ButtonNext: React.FC<ButtonNextProps> = ({ disabled, onClick }) => {
  return (
    <a
      className={
        cx(disabled && "opacity-5", "d-flex align-items-center text-grey-500 hover:text-grey-700 text-decoration-none")
      }
      href="#"
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      Prossimo
      <svg
        className="ml-2"
        width={20}
        height={20}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fillRule="evenodd"
          d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
          clipRule="evenodd"
        />
      </svg>
    </a>
  )
}

export default ButtonNext;
