import * as React from "react";
import { Fragment, useState } from "react";
import { useFormContext, useFieldArray } from "react-hook-form";

import FormInput from "./FormInput";
import { AddContactWindow } from "./AddContactWindow";
import ButtonRemove from "./ButtonRemove";
import {ContactTypes} from "./ClientForm";
import FormCheckbox from "./FormCheckbox";

interface ClientContactsProps {

}

const  ClientContacts: React.FC<ClientContactsProps> = (): JSX.Element => {
  const { control, register } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "contacts",
    keyName: "key"
  });

  const [modalOpen, setModalOpen] = useState(false);

  const showModal = () => {
    setModalOpen(true);
  };

  const addContact = (contact) => {
    append(contact);
    closeModal();
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const contactTypeToLabel = (type) => {
    if (type === ContactTypes.AMMINISTRATIVO) {
      return 'Amministrativo'
    }
    if (type ===  ContactTypes.COMMERCIALE) {
      return 'Commerciale'
    }
    if (type ===  ContactTypes.MARKETING) {
      return 'Marketing (su questo contatto verranno inoltrate le comunicazioni commerciali come promo o offerte)'
    }
    if (type ===  ContactTypes.ACQUISTI) {
      return 'Acquisti'
    }
    if (type === ContactTypes.TITOLARE) {
      return 'Titolare'
    }
    if (type === ContactTypes.MAGAZZINO) {
      return 'Magazzino'
    }
  }

  return (
    <div>
      {fields.map((contact, index) => {
        return (
          <Fragment key={contact.key}>
            <div className="row justify-content-between align-items-center">
              <p className="label">{contactTypeToLabel(contact.type)}:</p>
              {!contact.primary && <ButtonRemove onClick={() => { remove(index) }} />}
            </div>

            {contact.id && (
              <input ref={register()} type="text" name={`contacts[${index}].id`} defaultValue={contact.id} hidden />
            )}
            <input ref={register()} type="text" name={`contacts[${index}].type`} defaultValue={contact.type} hidden />
            <input ref={register()} type="checkbox" name={`contacts[${index}].primary`} defaultChecked={contact.primary} hidden />

            <div className="row form-content form-border">
              <div className="col">
                <div className="row">
                  <div className="col">
                    <label>
                      <p>Nome</p>
                      <FormInput name={`contacts[${index}].first_name`} defaultValue={contact.first_name} />
                    </label>
                  </div>

                  <div className="col">
                    <label>
                      <p>Cognome</p>
                      <FormInput name={`contacts[${index}].last_name`} defaultValue={contact.last_name} />
                    </label>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <label>
                      <p>Recapito Telefonico</p>
                      <FormInput name={`contacts[${index}].phone_number`} defaultValue={contact.phone_number} />
                    </label>
                  </div>

                  <div className="col">
                    <label>
                      <p>Email</p>
                      <FormInput name={`contacts[${index}].email`} defaultValue={contact.email} />
                    </label>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <FormCheckbox
                      name={`contacts[${index}].receive_emails`}
                      defaultValue={contact.receive_emails}
                      label="Voglio ricevere su questa email: conferme d’ordine, bolle, tracking spedizioni, fatture."
                    />
                  </div>
                </div>
              </div>
            </div>
            <br/>
            <br/>
          </Fragment>
        )
      })}

      <button className="default d-print-none" type="button" onClick={showModal}>
        + Aggiungi un ulteriore contatto
      </button>

      <AddContactWindow
        isOpen={modalOpen}
        onConfirm={addContact}
        onCancel={closeModal}
      />
    </div>
  );
};

export default ClientContacts;
