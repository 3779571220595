import * as React from "react";
import { useState } from "react";
import axios from "axios";
import * as toastr from "toastr";

import { ConfirmationWindow } from "./ConfirmationWindow";
import { FormInputs } from "./ClientForm/ClientForm";

import { AdminClientContactForm } from "./admin/AdminClientContactForm";
import ButtonPrint from "./ButtonPrint";

interface AdminClientShowProps {
  token: string;
  contact: FormInputs & { id: string };
}

const AdminClientShow: React.FC<AdminClientShowProps> = ({ contact, token }) => {
  const [formIsSent, setFormIsSent] = useState(false);
  const [modalState, setModalState] = useState({ isOpen: false, onConfirm: null, onSave: null });

  const onFormSubmit = (formValues) => {
    const values = formValues;
    showConfirmationModal(() => onConfirm(values), () => onSave(values));
  }

  const showConfirmationModal = (onConfirm, onSave) => {
    setModalState({ isOpen: true, onConfirm, onSave });
  }

  const hideConfirmationWindow = () => {
    setModalState({ isOpen: false, onConfirm: null, onSave: null });
  }

  const onConfirm = (formValues) => {
    axios
      .put("/admins/contacts/" + contact.id, { ...formValues, status: 'confirmed', authenticity_token: token }).then(() => {
        setFormIsSent(true);
      })
      .catch((error) => {
        toastr.error(error.response.data.message);
      })
      .finally(() => {
        hideConfirmationWindow();
      })
  }

  const onSave = (formValues) => {
    axios
      .put("/admins/contacts/" + contact.id, { ...formValues, status: 'saved', authenticity_token: token }).then(() => {
        setFormIsSent(true);
      })
      .catch((error) => {
        toastr.error(error.response.data.message);
      })
      .finally(() => {
        hideConfirmationWindow();
      })
  }

  return (
    <div className="client-registration">
      <div className="container">
        <div className="row logo-header p-5">
          <div className="col text-center">
            <img src="/images/nwitaly_logo.svg" alt="logotype" />
          </div>
        </div>

        {formIsSent ? (
          <div className="alert alert-success text-center" role="alert">
            La richiesta è stata inviata con successo!
          </div>
        ) : (
          <>
            <div className="ml-3 d-flex align-items-center mb-3 d-print-none">
              <div className="mr-2 font-weight-bold" style={{ fontSize: "18px" }}>Print</div>
              <ButtonPrint />
            </div>
            <AdminClientContactForm onSubmit={onFormSubmit} initialValues={contact} />
            <ConfirmationWindow isOpen={modalState.isOpen} onConfirm={modalState.onConfirm} onSave={modalState.onSave}
              onCancel={hideConfirmationWindow} />
          </>
        )}

      </div>
    </div>
  )
}

export default AdminClientShow;
