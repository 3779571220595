import { Button, Modal } from "react-bootstrap";
import * as React from "react";

export const ConfirmationWindow = ({ isOpen, onConfirm, onCancel }) => (
    <Modal show={isOpen}>
        <Modal.Header closeButton onHide={onCancel}>
            <Modal.Title>Conferma del modulo</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <p>Sei sicuro di voler inviare i dati?</p>
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={onCancel}>No, non voglio</Button>
            <Button variant="primary" onClick={onConfirm}>Si, voglio inviare</Button>
        </Modal.Footer>
    </Modal>
);