import { Button, Modal } from "react-bootstrap";
import * as React from "react";
import { useState } from "react";
import { ContactTypes, ContactTypeOptions } from "./ClientForm";

const contactTemplate = {
  type: '',
  nome: '',
  cognome: '',
  phone_number: '',
  email: '',
  primary: false,
  receive_emails: false
};

export const AddContactWindow = ({ isOpen, onConfirm, onCancel }) => {
  const [selectedValue, setSelectedValue] = useState(ContactTypes.AMMINISTRATIVO);

  const onChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const onSubmit = () => {
    onConfirm({
      ...contactTemplate,
      type: selectedValue
    });
  };

  return (
    <Modal show={isOpen}>
      <Modal.Header closeButton onHide={onCancel}>
        <Modal.Title>Aggiungi contatto aggiuntivo</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <label>
          Seleziona il tipo di contatto
        </label>
        <select onChange={onChange} value={selectedValue}>
          {ContactTypeOptions.map(({ value, label }, index) => {
            return <option key={index} value={value}>{label}</option>
          })}
        </select>
      </Modal.Body>

      <Modal.Footer>
        <Button className="default" variant="secondary" onClick={onCancel}>Annulla</Button>
        <Button className="default" variant="primary" onClick={onSubmit}>Inserisci</Button>
      </Modal.Footer>
    </Modal>
  );
};
