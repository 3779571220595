import * as React from "react";
import { ChangeEvent } from "react";
import cx from "classnames";
import { useFormContext, FieldName } from "react-hook-form";

import { FormInputs } from "./ClientForm";

interface FormInputProps {
  className?: string;
  value: string | number;
  name?: FieldName<FormInputs>;
  label?: string;
}

const FormRadioCheckbox: React.FC<FormInputProps> = ({
  className,
  value,
  name  = "company_type",
  label = name
}) => {
  const { errors, setValue, register, watch } = useFormContext<FormInputs>();
  const watchValue = watch(name);
  const currentValue = Array.isArray(watchValue) ? watchValue[0] : watchValue;

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(name, e.target.value);
  };

  return (
    <label className={className}>
      <input
        className={cx({ invalid: errors[name] })}
        type="checkbox"
        checked={currentValue === value}
        value={value}
        onChange={onChange}
      />
      <input
        className="visually-hidden"
        ref={register()}
        type="radio"
        name={name}
        value={value}
      />
      {label}
    </label>
  );
};

export default FormRadioCheckbox;
