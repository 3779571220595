import * as React from "react";
import { useState } from "react";
import axios from "axios";
import * as toastr from "toastr";

import { ConfirmationWindow } from "./ClientConfirmationWindow";
import { ClientForm } from "./ClientForm";

export const ClientRegistration = () => {
  const [formIsSent, setFormIsSent] = useState(false);
  const [modalState, setModalState] = useState({ isOpen: false, onConfirm: null });

  const onFormSubmit = (formValues) => {
    showConfirmationModal(() => onConfirm(formValues));
  };

  const showConfirmationModal = (onConfirm) => {
    setModalState({ isOpen: true, onConfirm });
  };

  const hideConfirmationWindow = () => {
    setModalState({ isOpen: false, onConfirm: null });
  };

  const onConfirm = (formValues) => {
    axios
      .post("/client_contacts", formValues).then(() => {
        setFormIsSent(true);
      })
      .catch((error) => {
        toastr.error(error.response.data.message);
      })
      .finally(() => {
        hideConfirmationWindow();
      });
  };

  return (
    <div className="client-registration">
      <div className="container">
        <div className="row logo-header p-5">
          <div className="col text-center">
            <img src="/images/nwitaly_logo.svg" alt="logotype" />
          </div>
        </div>

        {formIsSent ? (
          <div className="alert alert-success text-center" role="alert">
            La richiesta è stata inviata con successo!
          </div>
        ) : (
          <>
            <ClientForm onSubmit={onFormSubmit} initialValues={null} submitLabel="Invia" />
            <ConfirmationWindow isOpen={modalState.isOpen} onConfirm={modalState.onConfirm} onCancel={hideConfirmationWindow} />
          </>
        )}

      </div>
    </div>
  );
};
