import * as React from "react";
import * as moment from "moment";
import ButtonPrint from "./ButtonPrint";

export default function AdminClientIndex(props) {
  return (
    <div className="client-index">
      <div className="container">
        <div className="row text-center mb-5">
          <div className="col-12">
            <img src="/images/nwitaly_logo.svg" alt="logotype"/>
          </div>
        </div>

        <div className="row form-admin-table">
          <div className="row w-100 text-center table-content">
            <div className="container">
              <div className="d-flex align-items-center font-weight-bold text-left ml-4 mb-3">
                <div className="mr-2">{"Lista contatti: "}</div>
                <ButtonPrint />
              </div>
              <div className="d-flex flex-nowrap mb-3 font-weight-bold">
                <div className="pr-2 pl-2" style={{ minWidth: "60px", maxWidth: "60px", width: "100%" }}>
                  ID
                </div>
                <div className="text-left pr-2 pl-2" style={{ minWidth: "200px", maxWidth: "200px", width: "100%" }}>
                  Codice autorizzazione
                </div>
                <div className="text-left pr-2 pl-2" style={{ minWidth: "200px", maxWidth: "200px", width: "100%" }}>
                  Nome azienda
                </div>
                <div className="text-center pr-2 pl-2" style={{ maxWidth: "100px", width: "100%" }}>
                  Partita IVA
                </div>
                <div className="text-center pr-2 pl-2" style={{ maxWidth: "100px", width: "100%" }}>
                  Città
                </div>
                <div className="text-center pr-2 pl-2" style={{ maxWidth: "100px", width: "100%" }}>
                  Cap
                </div>
                <div className="text-center pr-2 pl-2" style={{ maxWidth: "100px", width: "100%" }}>
                  Stato
                </div>
                <div className="text-center pr-2 pl-2 w-100">
                  Data di inserimento
                </div>
              </div>
              {props.contacts.map(((contact, idx) => (
                <div key={idx} className="d-flex flex-nowrap">
                  <div className="pr-2 pl-2" style={{ minWidth: "60px", maxWidth: "60px", width: "100%" }}>
                    <a href={"/admins/contacts/" + contact.id}>{contact.id}</a>
                  </div>
                  <div className="text-left pr-2 pl-2" style={{ minWidth: "200px", maxWidth: "200px", width: "100%" }}>
                    {contact.authorization_code}
                  </div>
                  <div className="text-truncate text-left pr-2 pl-2" style={{ minWidth: "200px", maxWidth: "200px", width: "100%" }}>
                    {contact.company_name}
                  </div>
                  <div className="text-center pr-2 pl-2" style={{ maxWidth: "100px", width: "100%" }}>
                    {contact.vat_number}
                  </div>
                  <div className="text-center pr-2 pl-2" style={{ maxWidth: "100px", width: "100%" }}>
                    {contact.city}
                  </div>
                  <div className="text-center pr-2 pl-2" style={{ maxWidth: "100px", width: "100%" }}>
                    {contact.zip}
                  </div>
                  <div className="text-center pr-2 pl-2" style={{ maxWidth: "100px", width: "100%" }}>
                    {contact.status}
                  </div>
                  <div className="text-center pr-2 pl-2 w-100">
                    {moment(contact.created_at).format('L')}
                  </div>
                </div>
              )))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};
