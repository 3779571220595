import * as React from "react";
import axios from "axios";
import * as toastr from "toastr";

export default function AdminIndex(props) {
    return (

        <div className="admin-index">
            <div className="container">
                <div className="row text-center">
                    <div className="col-12">
                        <img src="/images/nwitaly_logo.svg" alt="logotype" />
                    </div>
                </div>

                <div className="row form-admin-table">
                    <div className="col-12">

                        <div className="row text-center table-buttons">
                            <div className="col-12">
                                <a className="blue_button" href="/admins/new">Crea nuovo admin</a>
                                <a className="blue_button" href="/admins/password">Cambia password</a>
                            </div>
                        </div>

                        <div className="row text-center table-content">
                            <div className="col-12">
                                <label>Elenco degli amministratori:</label>
                                <div className="row">
                                    <div className="col-2">
                                        id
                                    </div>
                                    <div className="col-10">
                                        Email
                                    </div>
                                </div>
                                {props.admins.map((admin => (
                                    <div className="row form-content">
                                        <div className="col-2">
                                            {admin.id}
                                        </div>
                                        <div className="col-10">
                                            {admin.email}
                                        </div>
                                    </div>
                                )))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
};
