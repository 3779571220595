import * as React from "react";
import cx from "classnames";
import { get } from "lodash";
import { useFormContext } from "react-hook-form";

import { FormInputs } from "./ClientForm";

interface FormInputProps {
  name: string;
  type?: string;
  disabled?: boolean;
  defaultValue?: any;
}

const FormInput: React.FC<FormInputProps> = ({
  type = "text",
  disabled = false,
  name,
  defaultValue
}) => {
  const { errors, register } = useFormContext<FormInputs>();

  return (
    <input
      className={cx({ invalid: get(errors, name) })}
      ref={register()}
      type={type}
      name={name}
      defaultValue={defaultValue}
      disabled={disabled}
    />
  );
};

export default FormInput;
