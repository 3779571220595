import * as React from "react";
import { Fragment } from "react";

import FormInput from "./FormInput";
import FormRadioCheckbox from "./FormRadioCheckbox";
import Tooltip from "./Tooltip";

const ClientTaxData: React.FC<{}> = () => {
  return (
    <Fragment>
      <div className="row form-content">
        <div className="col-4 col-md-3 col-lg-2">
          <p className="label">PARTITA IVA:</p>
        </div>

        <div className="col-8 col-md-9 col-lg-10">
          <FormInput name="vat_number" />
        </div>
      </div>

      <div className="row form-content">
        <div className="col-4 col-md-3 col-lg-2">
          <p className="label">CODICE FISCALE:</p>
        </div>

        <div className="col-8 col-md-9 col-lg-10">
          <FormInput name="fiscal_code" />
        </div>
      </div>

      <div className="row form-content">
        <div className="col-4 col-md-3 col-lg-2">
            <p className="label">REGIME FORFETTARIO:</p>
          {/* <Tooltip id="vat_number" text="Sample text for PARTITA IVA">
          </Tooltip> */}
        </div>

        <div className="col-8 col-md-9 col-lg-10 d-flex align-items-center">
          <div className="checkbox-list d-flex align-items-center">
            <FormRadioCheckbox name="flat_rate" value="SI" label="SI" />
            <FormRadioCheckbox name="flat_rate" value="NO" label="NO" />
          </div>
        </div>
      </div>

      <div className="row form-content">
        <div className="col-4 col-md-3 col-lg-2">
          <p className="label">Indirizzo PEC:</p>
        </div>

        <div className="col-4">
          <FormInput name="electronic_invoice_type" />
        </div>
      </div>

      <div className="row form-content">
        <div className="col-4 col-md-3 col-lg-2">
          <p className="label">Codice destinatario:</p>
        </div>

        <div className="col-4">
          <FormInput name="electronic_invoice_value" />
        </div>
      </div>

      <div className="row form-content">
        <div className="col-4 col-md-3 col-lg-2">
            <p className="label">SPLIT PAYMENT:</p>
          {/* <Tooltip id="split_payment" text="Sample text for SPLIT PAYMENT">
          </Tooltip> */}
        </div>

        <div className="col-8 col-md-9 col-lg-10 checkbox-list">
          <FormRadioCheckbox name="split_payment" value="SOGGETTO" label="SOGGETTO" />
          <FormRadioCheckbox name="split_payment" value="NON SOGGETTO" label="NON SOGGETTO" />
        </div>
      </div>

      <br/>
      <br/>

      <h4 className="text-center mb-3">DATI BANCARI</h4>

      <div className="row form-content">
        <div className="col-4 col-md-3 col-lg-2">
          <p className="label">Banca</p>
        </div>

        <div className="col-8 col-md-9 col-lg-10">
          <div className="row">
            <div className="col">
              <FormInput name="banca" />
            </div>
            <div className="col">
              <label>
                <p>Ag/FiL</p>
                <FormInput name="ag_fil" />
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="row form-content">
        <div className="col-4 col-md-3 col-lg-2">
          <p className="label">Codice IBAN:</p>
        </div>

        <div className="col-8 col-md-9 col-lg-10">
          <FormInput name="iban" />
        </div>
      </div>

      <br/>
      <br/>

      <h4 className="text-center mb-3">SCELTA TIPO PAGAMENTO</h4>

      <div className="row">
        <div className="col">
          <FormRadioCheckbox className="w-auto d-flex justify-content-center" name="payment_type" value="carta" label="CARTA DI CREDITO" />
        </div>
        <div className="col d-flex justify-content-center">
          <FormRadioCheckbox className="w-auto d-flex justify-content-center" name="payment_type" value="bancario" label="BONIFICO BANCARIO ANTICIPATO" />
          {/* <Tooltip id="bottom_hint" exclamation={true} text="Sample text for <BONIFICO BANCARIO>" inStyle={{zIndex: 10, top: "-4px", left: "150px"}}>
          </Tooltip> */}
        </div>
      </div>
    </Fragment>
  )
}

export default ClientTaxData;
