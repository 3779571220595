import * as React from "react";
import FormCompanyTypeCheckbox from "./FormCompanyTypeCheckbox";
import FormInput from "./FormInput";
import FormCompanyTypeInput from "./FormCompanyTypeInput";
import Tooltip from "./Tooltip";

const ClientPersonalData: React.FC<{}> = () => {
  return (
    <div>
      <div className="row form-content">
        <div className="col-12 col-md-2">
          <Tooltip id="authorization_code" text="Inserire il codice comunicato dal vostro commerciale di riferimento">
            <p className="label">CODICE AUTORIZZAZIONE:</p>
          </Tooltip>
        </div>

        <div className="col-12 col-md-10">
          <FormInput name="authorization_code" />
        </div>
      </div>

      <div className="row form-content">
        <div className="col-12 col-md-2">
          <p className="label">RAGIONE SOCIALE:</p>
        </div>

        <div className="col-12 col-md-10">
          <FormInput name="company_name" />
        </div>
      </div>

      <div className="row form-content">
        <div className="col-12 col-md-2">
          <p className="label">FORMA GIURIDICA:</p>
        </div>

        <div className="row col-10 pr-0">
          <div className="col-12 col-lg-8 checkbox-list">
            <FormCompanyTypeCheckbox value="SPA" label="SPA" />
            <FormCompanyTypeCheckbox value="SRL" label="SRL" />
            <FormCompanyTypeCheckbox value="SRLS" label="SRLS" />
            <FormCompanyTypeCheckbox value="SAS" label="SAS" />
            <FormCompanyTypeCheckbox value="SNC" label="SNC" />
            <FormCompanyTypeCheckbox value="SDF" label="SDF" />
            <FormCompanyTypeCheckbox value="company_type_individ" label="indiv. di" />
          </div>
          <div className="col-12 col-lg-4 pl-2 pr-0 px-lg-0">
            <FormCompanyTypeInput />
          </div>

        </div>

      </div>

      <div className="row form-content">
        <div className="col-12 col-md-2">
          <p className="label">SEDE LEGALE / DOM. FISC. :</p>
        </div>

        <div className="col-12 col-md-10">
          <div className="row">
            <div className="col-12 col-md-8">
              <label>
                <p>VIA</p>
                <FormInput name="street" />
              </label>
            </div>

            <div className="col-6 col-md-4">
              <label>
                <p>N°</p>
                <FormInput name="number" />
              </label>
            </div>

            <div className="col-6">
              <label>
                <p>CITTÀ</p>
                <FormInput name="city" />
              </label>
            </div>

            <div className="col-6 col-md-3">
              <label>
                <p>CAP</p>
                <FormInput name="zip" />
              </label>
            </div>

            <div className="col-6 col-md-3">
              <label>
                <p>PR</p>
                <FormInput name="province" />
              </label>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />

      Da compilare solo se diverso dalla sede legale
      <div className="row form-content form-border">
        <div className="col-12 col-md-2">
          <p className="label">LUOGO DI CONSEGNA:</p>
        </div>

        <div className="col-12 col-md-10">
          <div className="row">

            <div className="col-10">
              <label>
                <p>VIA</p>
                <FormInput name="sh_street" />
              </label>
            </div>

            <div className="col-2">
              <label>
                <p>N°</p>
                <FormInput name="sh_number" />
              </label>
            </div>

            <div className="col-5 col-lg-8">
              <label>
                <p>CITTÀ</p>
                <FormInput name="sh_city" />
              </label>
            </div>

            <div className="col-3 col-lg-2">
              <label>
                <p>CAP</p>
                <FormInput name="sh_zip" />
              </label>
            </div>

            <div className="col-3 col-lg-2">
              <label>
                <p>PR</p>
                <FormInput name="sh_province" />
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientPersonalData;
