import * as React from "react";
import { ChangeEvent } from "react";
import cx from "classnames";
import { useFormContext, FieldName } from "react-hook-form";

import { FormInputs } from "./ClientForm";

interface FormInputProps {
  className?: string;
  name?: FieldName<FormInputs>;
  defaultValue?: any;
  label?: string;
}

const FormCheckbox: React.FC<FormInputProps> = ({
  className,
  name ,
  label = name,
  defaultValue
}) => {
  const { errors, setValue, watch } = useFormContext<FormInputs>();

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(name, e.target.checked);
  };

  return (
    <label className={className}>
      <input
        className={cx({ invalid: errors[name] })}
        type="checkbox"
        defaultChecked={defaultValue}
        onChange={onChange}
      />
      {label}
    </label>
  );
};

export default FormCheckbox;
