import * as yup from 'yup'

const ValidationSchema1 = yup.object().shape({
  authorization_code: yup.string().required(),
  company_name: yup.string().max(60).required(),
  company_type: yup.string().when('company_type_individ', {
    is: companyTypeIndiv => companyTypeIndiv !== 'on',
    then: yup.string().required()
  }),
  company_type_indiv_input: yup.string().when('company_type_individ', {
    is: companyTypeIndiv => companyTypeIndiv === 'on',
    then: yup.string().max(35).required()
  }),
  street: yup.string().required(),
  number: yup.string().required(),
  city: yup.string().required(),
  zip: yup.string().min(5).max(5).matches(/^[0-9]+$/i).required(),
  province: yup.string().min(2).max(2).matches(/^[a-zA-Z]+$/i).required(),
  sh_street: yup.string(),
  sh_number: yup.string(),
  sh_city: yup.string(),
  sh_zip: yup.string().notRequired().test('sh_zip', 'Last Name test message', function (value) {
    if (value) {
      const schema = yup.string().min(5).max(5).matches(/^[0-9]+$/i)
      return schema.isValidSync(value)
    }
    return true
  }),

  sh_province: yup.string().notRequired().test('sh_province', 'Last Name test message', function (value) {
    if (value) {
      const schema = yup.string().min(2).max(2).matches(/^[a-zA-Z]+$/i)
      return schema.isValidSync(value)
    }
    return true
  }),

  sh_telephone: yup.string(),
  sh_fax: yup.string(),
  sh_delivery_time: yup.string(),
  sh_delivery_notes: yup.string()
})

const ValidationSchema2 = ValidationSchema1.shape({
  vat_number: yup.string().matches(/^\d+$/).length(11).required(),
  fiscal_code: yup.string().max(16).matches(/^[a-z0-9]+$/i).required(),
  flat_rate: yup.string().required(),
  electronic_invoice_type: yup.string().when('electronic_invoice_value', {
    is: electronic_invoice_value => !electronic_invoice_value || electronic_invoice_value.length === 0,
    then: yup.string().max(50).email().required(),
    otherwise: yup.string().max(50).email()
  }),
  electronic_invoice_value: yup.string().when('electronic_invoice_type', {
    is: electronic_invoice_type => !electronic_invoice_type || electronic_invoice_type.length === 0,
    then: yup.string().matches(/^[a-z0-9]+$/i).length(7).required(),
    otherwise: yup.string().matches(/^[a-z0-9]*$/i).max(7)
  }),
  split_payment: yup.string().required(),
  banca: yup.string().required(),
  ag_fil: yup.string().required(),
  iban: yup.string().matches(/^[a-z0-9]+$/i).length(27).required(),
  payment_type: yup.string().required()
}, [['electronic_invoice_type', 'electronic_invoice_value']])

const ValidationSchema3 = ValidationSchema2.shape({
  consent_a: yup.string().matches(/yes/).required(),
  consent_b: yup.string().required()
})

const ValidationSchema4 = ValidationSchema3.shape({
  contacts: yup.array().of(
    yup.object().shape({
      first_name: yup.string().required(),
      last_name: yup.string().required(),
      phone_number: yup.string().required(),
      email: yup.string().email().required()
    })
  )
})

export {
  ValidationSchema1,
  ValidationSchema2,
  ValidationSchema3,
  ValidationSchema4
}
